<template>
  <div class="card border-0">
    <div class="card-header f-w-600"><h4>证件照片</h4></div>
    <div class="card-body height-400 m-0 p-0">
      <form method="POST">
        <!-- 企业证件 -->
        <div class="panel panel-inverse" data-sortable-id="form-stuff-1">
          <div class="panel-body">
            <div class="row form-group m-b-10">
              <dt
                v-for="(src, index) in License"
                :key="index"
                class="text-inverse text-right col-2 text-truncate"
              >
                <a href="#" @click="removeRowEvent(src.id)">删除</a>
              </dt>
              <dl class="row">
                <dd class="col-9 text-truncate">
                  <viewer :images="License" class="images clearfix">
                    <img
                      v-for="(src, index) in License"
                      :key="index"
                      :src="
                        baseUrl+ '/api/file-management/download/' +
                        src.fileId
                      "
                    />
                    <!-- <span class="cancle" @click="removeRowEvent()">×</span> -->
                  </viewer>
                </dd>
              </dl>
            </div>
          </div>
        </div>
      </form>
    </div>
    <div class="card-footer text-right f-w-600">
      <button
        type="button"
        @click="$emit('close')"
        class="btn btn-sm btn-default"
      >
        ❌关闭
      </button>
    </div>
  </div>
</template>
<script>
import { businessApi } from "@/api/system/businessApi";
export default {
  props: ["item"],
  data() {
    return {
      License: [],
    };
  },
  mounted() {
    let options = [];
    if (this.item.length == 0) {
      return;
    }
    this.item.forEach(function (item) {
      options.push({ fileId: item.fileId, id: item.id });
    });
    // this.License=["https://dqhaj.e-clouds.net/api/file-management/download/3a0386b9-e4ae-c2a2-86d4-aa6bfc368fcb","https://dqhaj.e-clouds.net/api/file-management/download/3a0386b9-e4ae-c2a2-86d4-aa6bfc368fcb"];
    this.License = options;
  },
  methods: {
    ToastSuccess(res) {
      const Toast = this.$swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 2000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener("mouseenter", this.$swal.stopTimer);
          toast.addEventListener("mouseleave", this.$swal.resumeTimer);
        },
      });
      var title = [];
      if (res.message == "Success") {
        title = "删除成功";
      } else {
        title = res.message;
      }
      Toast.fire({
        icon: "success",
        title: title,
      });
    },

    // 删除证照情况
    removeRowEvent(Businessfileid) {
      this.$swal({
        // title: "Are you sure?",
        icon: "warning",
        text: "是否确定删除?",
        type: "info",
        showCancelButton: true, //确定按钮
        buttonsStyling: false,
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        confirmButtonClass: "btn m-r-5 btn-" + "info" + "", //按钮颜色设置
        cancelButtonClass: "btn btn-default", //按钮类型设置
      }).then((isConfirm) => {
        try {
          //判断 是否 点击的 确定按钮
          if (isConfirm.value) {
            let that = this;
            businessApi
              .DeleteBusinessfile({ id: Businessfileid })
              .then((res) => that.DeleteBusinessfileSuccess(that, res))
              .finally(() => {});
          } else {
            console.log(isConfirm);
          }
        } catch (e) {
          alert(e);
        }
      });
    },
    DeleteBusinessfileSuccess(that, res) {
      if (res.success) {
        that.ToastSuccess(res);
        this.$emit("close");
      } else {
        console.log(res);
      }
    },
  },
};
</script>
<style scoped>
.images img {
  width: calc(50% - 10px);
  min-width: 150px;
  cursor: pointer;
  margin: 5px;
  display: inline-block;
}
.dz-image .cancle {
  color: #999;
  font-size: 50px;
  position: absolute;
  right: 4px;
  top: -4px;
}
.imgWrap .right .cancle {
  color: #999;
  font-size: 50px;
  position: absolute;
  right: 4px;
  top: -4px;
}
</style>
